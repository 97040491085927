"use client";

import { usePlayer } from "@packages/media";
import type { QueueContentType, ReferenceType } from "@packages/sdk";
import type { RGBAObject, ThumbnailProps, WithStylexArray } from "@packages/ui";
import {
  stylexDynamics,
  Thumbnail,
  useHover,
  useIsDesktopViewport,
  usePress,
} from "@packages/ui";
import {
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";
import {
  type ComponentProps,
  forwardRef,
  type ReactNode,
  useCallback,
} from "react";

import type { MetaData } from "../../lib";
import { ContentItemContent as Content } from "../ContentItemContent";

// Importing pseudo-classes from another file is currently not supported by stylex
const cssMediaIsDesktopViewport = "@media (min-width: 768px)";

const styles = stylex.create({
  container: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    maxWidth: numericPercentages[100],
    scrollSnapAlign: "center",
    transition: "opacity 150ms ease-in, scale 150ms ease-in",
    width: "fit-content",
  },
  hoveredContainer: {
    scale: 1.02,
  },
  pressedContainer: {
    opacity: 0.6,
    scale: 0.975,
  },
  rightActionContainer: {
    alignItems: "center",
    color: semanticColors.primary,
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    paddingRight: spacing.m,
  },
  image: {
    background: semanticColors.neutralsMedium,
    flexShrink: 0,
  },
  disabledContainer: {
    cursor: "default",
  },
});

const sizeContainerStyles = stylex.create({
  xs: {
    maxWidth: numericPercentages[100],
    width: numericPercentages[100],
  },
  s: {
    maxWidth: numericPercentages[100],
    width: numericPercentages[100],
  },
  m: {
    maxWidth: { [cssMediaIsDesktopViewport]: 210, default: 160 },
    // 124px minimum supports screens down to 300px width
    // 168px minimum supports screens 768px width and up
    minWidth: { [cssMediaIsDesktopViewport]: 168, default: 124 },
    width: numericPercentages[100],
  },
  l: {
    minWidth: {
      [cssMediaIsDesktopViewport]: numericPercentages[100],
      default: 327,
    },
    width: numericPercentages[100],
  },
});

const stylesMediumLarge = stylex.create({
  container: {
    alignItems: "start",
    flexDirection: "column",
    justifyContent: "center",
    scrollSnapAlign: "center",
  },
});

const stylesLargeDesktop = stylex.create({
  container: {
    borderRadius: radius.l,
    cursor: "pointer",
    display: "flex",
    outline: `${numericPixels[4]} solid transparent`,
    overflow: "hidden",
    scrollSnapAlign: "start",
    transition:
      "outline 150ms ease-in, opacity 150ms ease-in, scale 150ms ease-in",
  },
  hoveredContainer: {
    outline: `${numericPixels[4]} solid ${semanticColors.neutralsLowest}`,
    scale: 1.003,
  },
  pressedContainer: {
    opacity: 0.4,
    scale: 0.99,
  },
  imageContainer: {
    borderBottomRightRadius: radius.l,
    borderTopRightRadius: radius.l,
  },
  disabledContainer: {
    cursor: "default",
  },
});

const imageSizesHoverStyles = stylex.create({
  xs: {
    outline: {
      [cssMediaIsDesktopViewport]: `${numericPixels[4]} solid ${semanticColors.neutralsLowest}`,
      default: `${numericPixels[2]} solid ${semanticColors.neutralsLowest}`,
    },
    transition: "outline 150ms ease-in",
  },
  s: {
    outline: {
      [cssMediaIsDesktopViewport]: `${numericPixels[4]} solid ${semanticColors.neutralsLowest}`,
      default: `${numericPixels[2]} solid ${semanticColors.neutralsLowest}`,
    },
    transition: "outline 150ms ease-in",
  },
  m: {
    outline: {
      [cssMediaIsDesktopViewport]: `${numericPixels[4]} solid ${semanticColors.neutralsLowest}`,
      default: `${numericPixels[2]} solid ${semanticColors.neutralsLowest}`,
    },
    transition: "outline 150ms ease-in",
  },
  l: {
    outline: {
      [cssMediaIsDesktopViewport]: `${numericPixels[4]} solid ${semanticColors.neutralsLowest}`,
      default: `${numericPixels[2]} solid ${semanticColors.neutralsLowest}`,
    },
    transition: "outline 150ms ease-in",
  },
});

export type ContentItemProps = WithStylexArray<ComponentProps<"div">> & {
  description?: string;
  metaData?: MetaData;
  rightAction?: ReactNode;
  backgroundColor?: RGBAObject;
  childProps?: Omit<ComponentProps<"div">, "onPlay">;
  disabled?: boolean;
  referenceType?: ReferenceType;
  referenceId?: number;
} & Pick<ThumbnailProps, "size" | "title" | "imageSrc" | "imageOverlay">;

const DEFAULT_LARGE_DESKTOP_CONTENT_ITEM_BACKGROUND = {
  r: 0,
  g: 0,
  b: 0,
  a: 255,
};

export const ContentItem = forwardRef<HTMLDivElement, ContentItemProps>(
  (
    {
      styleXArray = [],
      size,
      imageSrc,
      imageOverlay,
      title,
      description,
      metaData = {},
      rightAction,
      childProps,
      disabled = false,
      backgroundColor = DEFAULT_LARGE_DESKTOP_CONTENT_ITEM_BACKGROUND,
      referenceType,
      referenceId,
      ...props
    },
    ref,
  ) => {
    const player = usePlayer();
    const { hoverProps, isHovered } = useHover({ isDisabled: disabled });
    const { pressProps, isPressed } = usePress({ isDisabled: disabled });
    const isTablet = useIsDesktopViewport();

    const onPlay: MouseEventHandler<HTMLButtonElement> = useCallback(
      async (e) => {
        if (!referenceType || !referenceId) return;

        if (
          !["chapter", "challenge", "collection", "prayer", "audio"].includes(
            referenceType,
          )
        )
          return;
        e.stopPropagation();
        await player?.playNew({
          type: referenceType as QueueContentType,
          id: referenceId,
        });
      },
      [player, referenceId, referenceType],
    );

    if (size === "l" && isTablet) {
      return (
        <div
          ref={ref}
          {...hoverProps}
          {...pressProps}
          {...stylex.props(
            stylesLargeDesktop.container,
            sizeContainerStyles[size],
            stylexDynamics.backgroundColorFromRGBAObject(backgroundColor),
            isHovered && stylesLargeDesktop.hoveredContainer,
            isPressed && stylesLargeDesktop.pressedContainer,
            disabled && stylesLargeDesktop.disabledContainer,
            styleXArray,
          )}
          {...props}
        >
          <Content
            title={title}
            description={description}
            metaData={metaData}
            size={size}
            onPlay={onPlay}
          />
          <Thumbnail
            imageSrc={imageSrc}
            imageOverlay={imageOverlay}
            size={size}
            title={title}
            gradientColor={backgroundColor}
            styleXArray={[stylesLargeDesktop.imageContainer]}
          />
        </div>
      );
    }

    return (
      <div
        ref={ref}
        {...hoverProps}
        {...pressProps}
        {...stylex.props(
          styles.container,
          sizeContainerStyles[size],
          (size === "m" || size === "l") && stylesMediumLarge.container,
          isHovered && styles.hoveredContainer,
          isPressed && styles.pressedContainer,
          disabled && styles.disabledContainer,
          styleXArray,
        )}
        {...props}
      >
        <Thumbnail
          imageSrc={imageSrc}
          imageOverlay={imageOverlay}
          size={size}
          title={title}
          styleXArray={isHovered ? [imageSizesHoverStyles[size]] : []}
          {...childProps}
        />
        <Content
          title={title}
          description={description}
          metaData={metaData}
          size={size}
          onPlay={onPlay}
          {...childProps}
        />
        {["xs", "s"].includes(size) && rightAction && (
          <div {...stylex.props(styles.rightActionContainer)}>
            {rightAction}
          </div>
        )}
      </div>
    );
  },
);

ContentItem.displayName = "ContentItem";
