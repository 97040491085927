import type { FlaggedRecord } from "@packages/sdk";
import { Avatar, Text, type WithStylexArray } from "@packages/ui";
import {
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import type { HTMLAttributes } from "react";

const styles = stylex.create({
  base: {
    display: "flex",
    gap: spacing.s,
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
  },
  baseAligned: {
    alignItems: "center",
  },
  text: {
    color: semanticColors.neutralsHigher,
    flex: 1,
  },
  textName: {
    color: semanticColors.neutralsMedHigh,
  },
});

export type ReportedCommentProps = WithStylexArray<
  Omit<HTMLAttributes<HTMLDivElement>, "children">
> & {
  data: FlaggedRecord;
};

export const ReportedComment = ({ data, ...props }: ReportedCommentProps) => (
  <div
    {...props}
    {...stylex.props(
      styles.base,
      data.comment?.length <= 45 ? styles.baseAligned : null,
    )}
  >
    <Avatar imgSrc={data.reported_by?.image_url} size={40} />
    <Text size="m" styleXArray={[styles.text]} type="body">
      <Text size="m" styleXArray={[styles.textName]} type="title">
        {data.reported_by?.name ? `${data.reported_by?.name} ` : ""}
        {data.reported_by?.last_name ? `${data.reported_by?.last_name} ` : ""}
      </Text>
      {data.comment}
    </Text>
  </div>
);

ReportedComment.displayName = "ReportedComment";
