import { I18nMessageClient } from "@packages/i18n";
import { type FlaggedPromptResponse, useDate } from "@packages/sdk";
import type { MaskProps, WithStylexArray } from "@packages/ui";
import { Avatar, ScrollArea, Text } from "@packages/ui";
import {
  radius,
  semanticColors,
  spacing,
  stroke,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import type { HTMLAttributes } from "react";

const styles = stylex.create({
  base: {
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.m,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    display: "flex",
    flexDirection: "column",
    padding: spacing.m,
  },
  header: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
    marginBottom: spacing.ms,
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.t,
  },
  headerTextTime: {
    color: semanticColors.neutralsMedium,
  },
  text: {
    color: semanticColors.onColorHighest,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  textPrompt: {
    color: semanticColors.onColorVeryHigh,
  },
  scrollAreaViewport: (props: { backgroundColor: string }) => ({
    backgroundColor: props.backgroundColor,
    borderRadius: radius.m,
    maxHeight: 320,
    padding: spacing.l,
  }),
  scrollAreaViewportMaskBottom: {
    borderBottomLeftRadius: radius.m,
    borderBottomRightRadius: radius.m,
  },
  scrollAreaViewportMaskTop: {
    borderTopLeftRadius: radius.m,
    borderTopRightRadius: radius.m,
  },
});

export type ReportedCardPromptResponseProps = WithStylexArray<
  Omit<HTMLAttributes<HTMLDivElement>, "children">
> & {
  data: FlaggedPromptResponse;
};

export const ReportedCardPromptResponse = ({
  data,
  styleXArray,
  ...props
}: ReportedCardPromptResponseProps) => {
  const { getDateFormatDifferenceNow } = useDate();

  return (
    <div {...props} {...stylex.props(styles.base, styleXArray)}>
      <div {...stylex.props(styles.header)}>
        <Avatar imgSrc={data.user.image_url} size={40} />
        <div {...stylex.props(styles.headerText)}>
          <Text size="xl" type="detail">
            <strong>{data.user?.name ? `${data.user?.name} ` : ""}</strong>
            <I18nMessageClient message="responded_to_a_discussion" />
          </Text>
          <Text size="l" styleXArray={[styles.headerTextTime]} type="detail">
            {getDateFormatDifferenceNow({ date: new Date(data.created_at) })}
          </Text>
        </div>
      </div>
      <ScrollArea
        maskBottom
        maskBottomStyleXArray={[styles.scrollAreaViewportMaskBottom]}
        masksColor={data.prayer?.images?.color_hex as MaskProps["color"]}
        maskTop
        maskTopStyleXArray={[styles.scrollAreaViewportMaskTop]}
        viewportStyleXArray={[
          styles.scrollAreaViewport({
            backgroundColor: data.prayer?.images
              ?.color_hex as MaskProps["color"],
          }),
        ]}
      >
        <div {...stylex.props(styles.textContainer)}>
          <Text
            as="span"
            size="l"
            styleXArray={[styles.textPrompt]}
            type="quote"
          >
            {data.prayer?.prompt}
          </Text>
          <Text as="p" size="l" styleXArray={[styles.text]} type="body">
            {data.text}
          </Text>
        </div>
      </ScrollArea>
    </div>
  );
};

ReportedCardPromptResponse.displayName = "ReportedCardPromptResponse";
