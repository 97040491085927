import { I18nMessageClient } from "@packages/i18n";
import type { StackProps } from "@packages/ui";
import { Stack, Text } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";

const styles = stylex.create({
  stack: {
    alignItems: "center",
  },
});

export type ReportedCarouselEmptyProps = Omit<StackProps, "children">;

export const ReportedCarouselEmpty = ({
  styleXArray,
  ...props
}: ReportedCarouselEmptyProps) => (
  <Stack gap={spacing.xxs} styleXArray={[styles.stack, styleXArray]} {...props}>
    <Text as="h2" size="l" type="title">
      <I18nMessageClient message="pad_nothing_to_review" />
    </Text>
    <Text as="p" size="xl" type="detail">
      <I18nMessageClient message="pad_thank_you_safe" />
    </Text>
  </Stack>
);

ReportedCarouselEmpty.displayName = "ReportedCarouselEmpty";
