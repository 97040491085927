import { type FlaggableObjectWithRecords, URL_BASE } from "@packages/sdk";
import type { WithStylexArray } from "@packages/ui";
import {
  Button,
  FLAG_RECORD_REASON_OPTIONS,
  IconButton,
  InfoIcon,
  Text,
  useConfirm,
} from "@packages/ui";
import {
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useTranslations } from "next-intl";

import { ReportedCard } from "../ReportedCard";
import { ReportedComment } from "../ReportedComment";

const styles = stylex.create({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  reportedForContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  reportedForSublabelText: {
    color: semanticColors.neutralsMedium,
  },
  reportedForHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  reportedForList: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  reportedReasonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    paddingVertical: spacing.l,
  },
  commentContainer: {
    display: "flex",
    gap: spacing.s,
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.s,
  },
  commentTextContainer: {
    display: "inline-flex",
  },
  commentReporterNameText: {
    color: semanticColors.neutralsHigher,
  },
  commentText: {
    color: semanticColors.neutralsMedHigh,
  },
  dialogPrimaryButton: {
    color: semanticColors.accentMedium,
  },
});

export type ReportedObjectProps = WithStylexArray<{
  flaggableObject: FlaggableObjectWithRecords;
}>;

export const ReportedObject = ({
  flaggableObject,
  styleXArray,
}: ReportedObjectProps) => {
  const t = useTranslations();

  const dialog = useConfirm({
    description: () => t("pad_reported_description"),
    primaryButton: () => (
      <Button
        asChild
        styleXArray={[styles.dialogPrimaryButton]}
        variant="outline"
      >
        <Link href={`${URL_BASE}/community-guidelines`} target="_blank">
          {t("dialog_report_guidelines_button")}
        </Link>
      </Button>
    ),
    showCloseButton: true,
    size: "l",
    title: () => t("pad_review_reported_posts"),
  });

  return (
    <div {...stylex.props(styles.container, styleXArray)}>
      <div {...stylex.props(styles.reportedForContainer)}>
        <div {...stylex.props(styles.reportedForHeader)}>
          <Text size="l" type="title" as="h2">
            {t("parish_admin_dashboard_moderation_reported_for")}
          </Text>
          <IconButton
            onClick={async () => await dialog.show()}
            icon={<InfoIcon />}
            variant="transparentNeutral"
          />
        </div>
        {flaggableObject.flagged_records?.length > 0 && (
          <div {...stylex.props(styles.reportedForList)}>
            {flaggableObject.flagged_records.map((record, index) => {
              const flaggedReason = FLAG_RECORD_REASON_OPTIONS.find(
                (reason) => reason.value === record.reason,
              );
              return (
                <div
                  key={`${flaggableObject.flaggable_id}-${flaggableObject.flaggable_type}-${record.reported_by?.id}-${record.reason}-${index}`}
                  {...stylex.props(styles.reportedReasonContainer)}
                >
                  <Text type="title" size="m">
                    {t(flaggedReason.labelLokaliseKey)}
                  </Text>
                  <Text
                    type="body"
                    size="m"
                    styleXArray={[styles.reportedForSublabelText]}
                  >
                    {t(flaggedReason.sublabelLokaliseKey)}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
        <div {...stylex.props(styles.detailsContainer)}>
          <ReportedCard
            data={flaggableObject.flaggable}
            type={flaggableObject.flaggable_type}
          />
          {flaggableObject.flagged_records.map((record, index) => {
            return (
              <ReportedComment
                data={record}
                key={`${flaggableObject.flaggable_id}-${flaggableObject.flaggable_type}-${record.reported_by?.id}-${record.comment}-${index}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

ReportedObject.displayName = "ReportedObject";
