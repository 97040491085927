import { InlineIcon, Text, useIsDesktopViewport } from "@packages/ui";
import {
  numericPercentages,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";

import type { MetaData } from "../../lib/types/content";
import type { ContentItemProps } from "../ContentItem";

const styles = stylex.create({
  metaDataContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xs,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: numericPercentages[100],
  },
  metaDataText: { color: semanticColors.neutralsMedium },
  metaDataIconContainer: { color: semanticColors.neutralsLower, flexShrink: 0 },
  contentText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: numericPercentages[100],
  },
});

const stylesLargeDesktop = stylex.create({
  metaDataText: { color: semanticColors.onColorVeryHigh },
});

export const ContentItemMetaData = ({
  text,
  icon,
  size,
}: MetaData & Pick<ContentItemProps, "size">) => {
  const isDesktopViewport = useIsDesktopViewport();

  return (
    <div {...stylex.props(styles.metaDataContainer)}>
      {icon && (
        <div {...stylex.props(styles.metaDataIconContainer)}>
          <InlineIcon icon={icon} />
        </div>
      )}
      <Text
        type="detail"
        size={size === "l" ? "xl" : "l"}
        styleXArray={[
          styles.contentText,
          isDesktopViewport && size === "l"
            ? stylesLargeDesktop.metaDataText
            : styles.metaDataText,
        ]}
      >
        {text}
      </Text>
    </div>
  );
};
