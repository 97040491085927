"use client";

import Bugsnag from "@bugsnag/js";
import type { BugsnagErrorBoundary as BugsnagBoundary } from "@bugsnag/plugin-react";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Consent } from "@packages/analytics";
import {
  getValidatedCookiesHighPrivacyZone,
  getValidatedCookiesHlwConsent,
  setCookiesHighPrivacyZone,
  useRequestMe,
  useSession,
} from "@packages/sdk";
import React, { useEffect } from "react";

/*
    Setup Bugsnag
    "autoTrackSessions" set to false
    "onError" check that we have consent
*/
Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_ENV,
  enabledReleaseStages: ["staging", "production"],
  onError: () =>
    getValidatedCookiesHlwConsent()?.includes(Consent.ANALYTICS) ||
    !getValidatedCookiesHighPrivacyZone(),
  autoTrackSessions: false,
});

const ErrorBoundary: BugsnagBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

const BugsnagErrorBoundary = ({ children }) => {
  const { status } = useSession();

  const { query } = useRequestMe();

  const consentCookie = getValidatedCookiesHlwConsent();

  // TODO: copypasta from consent PR, can DRY as refactor
  useEffect(() => {
    const fetchIpDetails = async () => {
      let ipDetails = null;

      try {
        const ipResponse = await fetch(
          `https://ipwhois.pro/json/?key=${process.env.NEXT_PUBLIC_WHOIS_KEY}`,
        );
        ipDetails = await ipResponse.json();
      } catch (err) {
        console.error(err);
      }

      if (highPrivacy(ipDetails)) {
        setCookiesHighPrivacyZone(JSON.stringify(true));
      } else {
        setCookiesHighPrivacyZone(JSON.stringify(false));
      }
    };

    fetchIpDetails();
  }, []);

  // determine whether to we are in a high privacy zone
  const highPrivacy = (ipDetails) => {
    let highPrivacy = true;

    // if we received ipDetails, check our locale
    if (ipDetails && ipDetails.success) {
      const { continent_code, continent, country_code, country, region } =
        ipDetails;

      if (
        !(
          continent_code?.toLowerCase() === "eu" ||
          continent?.toLowerCase() === "europe" ||
          ((country_code?.toLowerCase() === "us" ||
            country?.toLowerCase() === "united states") &&
            region?.toLowerCase() === "california") ||
          ["brazil", "south africa"].includes(country?.toLowerCase())
        )
      )
        highPrivacy = false;
    }

    return highPrivacy;
  };

  // on user change, update Bugsnag session tracking and user profile
  useEffect(() => {
    if (
      consentCookie?.includes(Consent.ANALYTICS) ||
      !getValidatedCookiesHighPrivacyZone()
    ) {
      Bugsnag.resumeSession();

      if (status === "authenticated" && query?.data) {
        Bugsnag.setUser(
          query.data.id as unknown as string,
          query.data.email ?? "no email",
          query.data.name ?? "no name",
        );
      }
    } else {
      Bugsnag.pauseSession();
    }
    // TODO: how do we listen for / await a cookie change in React/NextJS?
  }, [consentCookie, query?.data, status]);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default BugsnagErrorBoundary;
