import { animations } from "@packages/ui/global/stylex/animations.stylex";
import * as stylex from "@stylexjs/stylex";
import React from "react";

const styles = stylex.create({
  base: {
    display: "flex",
    justifyContent: "center",
  },
  animation: {
    animationDuration: "0.5s",
    animationIterationCount: "infinite",
    animationName: animations.rotate,
    animationTimingFunction: "linear",
  },
});

type LoaderProps = {};

export const Loader = ({}: LoaderProps) => (
  <div {...stylex.props(styles.base)}>
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...stylex.props(styles.animation)}
    >
      <defs>
        <linearGradient id="spinnerGradient" x1="0" x2="1" y1="0" y2="0">
          <stop offset="0%" stopColor="#6D0EC1" stopOpacity="0" />
          <stop offset="100%" stopColor="#6D0EC1" stopOpacity="1" />
        </linearGradient>
      </defs>
      <path
        d="M7 23A18 18 0 0 1 43 27"
        strokeWidth="7"
        stroke="url(#spinnerGradient)"
        strokeLinecap="round"
      />
    </svg>
  </div>
);
