import { useTranslations } from "@packages/i18n";
import { Button, PlayIcon, Text, useIsDesktopViewport } from "@packages/ui";
import {
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import type { ComponentProps, MouseEventHandler } from "react";

import type { MetaData } from "../../lib/types/content";
import type { ContentItemProps } from "../ContentItem";
import { ContentItemMetaData } from "../ContentItemMetadata";

const styles = stylex.create({
  contentTextContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xs,
    overflow: "hidden",
    padding: spacing.none,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: numericPercentages[100],
  },
  titleDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: numericPercentages[100],
  },
  contentText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: numericPercentages[100],
  },
});

const stylesMediumLarge = stylex.create({
  contentTextContainer: {
    paddingHorizontal: spacing.xxs,
  },
});

const stylesLargeDesktop = stylex.create({
  contentContainer: {
    alignItems: "flex-start",
    borderBottomLeftRadius: radius.l,
    borderTopLeftRadius: radius.l,
    display: "flex",
    flex: "1 0 0",
    flexDirection: "column",
    gap: spacing.l,
    height: numericPixels[480],
    justifyContent: "center",
    padding: spacing.none,
    paddingLeft: `min(${numericPixels[80]}, 7vw)`,
    paddingRight: numericPixels[1],
  },
  contentTextContainer: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    paddingHorizontal: spacing.xs,
  },
  titleDescriptionContainer: {
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
  },
  contentButtonsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
  contentText: {
    maxWidth: `min(${numericPixels[480]}, 28vw)`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  titleText: {
    color: semanticColors.onColorHighest,
  },
  descriptionText: {
    color: semanticColors.onColorHighest,
  },
});

export const ContentItemContent = ({
  title,
  description,
  metaData,
  size,
  onPlay,
  ...props
}: Pick<ContentItemProps, "title" | "description" | "metaData" | "size"> & {
  metaData?: MetaData;
  onPlay?: MouseEventHandler<HTMLButtonElement>;
} & Omit<ComponentProps<"div">, "title" | "onPlay">) => {
  const isDesktopViewport = useIsDesktopViewport();
  const t = useTranslations();

  if (size === "l" && isDesktopViewport) {
    return (
      <div {...stylex.props(stylesLargeDesktop.contentContainer)} {...props}>
        <div {...stylex.props(stylesLargeDesktop.contentTextContainer)}>
          <div {...stylex.props(stylesLargeDesktop.titleDescriptionContainer)}>
            <Text
              type="headline"
              size="l"
              styleXArray={[
                stylesLargeDesktop.contentText,
                stylesLargeDesktop.titleText,
              ]}
            >
              {title}
            </Text>
            {description && (
              <Text
                type="body"
                size="m"
                styleXArray={[
                  stylesLargeDesktop.contentText,
                  stylesLargeDesktop.descriptionText,
                ]}
              >
                {description}
              </Text>
            )}
          </div>
          {metaData && (
            <ContentItemMetaData
              icon={metaData.icon}
              text={metaData.text}
              size={size}
            />
          )}
        </div>
        <div {...stylex.props(stylesLargeDesktop.contentButtonsContainer)}>
          <Button
            variant="secondary"
            startIcon={<PlayIcon.Medium style={{ padding: spacing.xs }} />}
            size="m"
            onClick={onPlay}
          >
            {t("play_session")}
          </Button>
          <Button variant="lighten" size="m">
            {t("general_word_details")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      {...stylex.props(
        styles.contentTextContainer,
        (size === "m" || size === "l") &&
          stylesMediumLarge.contentTextContainer,
      )}
      {...props}
    >
      <div {...stylex.props(styles.titleDescriptionContainer)}>
        <Text
          type="title"
          size={
            (!isDesktopViewport && size === "l") || isDesktopViewport
              ? "l"
              : "m"
          }
          styleXArray={[styles.contentText]}
        >
          {title}
        </Text>
        {description && (
          <Text type="body" size="s" styleXArray={[styles.contentText]}>
            {description}
          </Text>
        )}
      </div>
      {metaData && (
        <ContentItemMetaData
          icon={metaData.icon}
          text={metaData.text}
          size={size}
        />
      )}
    </div>
  );
};
