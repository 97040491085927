"use client";

import type { UseRedirectProps } from "../../lib";
import { useRedirect } from "../../lib";

/**
 * This is a component representation of useRedirect
 * For usage within RSCs
 */

export type RedirectProps = UseRedirectProps;

export const Redirect = ({ redirectPath, sessionStatus }: RedirectProps) => {
  useRedirect({ redirectPath, sessionStatus });

  return null;
};
