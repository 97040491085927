"use client";

import { HallowAnalyticsEvent } from "@packages/analytics";
import type { SidebarProps, SidebarTabProps } from "@packages/ui";
import {
  InfoIcon,
  PermissionIcon,
  Sidebar,
  SidebarTab,
  Text,
} from "@packages/ui";
import {
  semanticColors,
  spacing,
  stroke,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { useParams } from "next/navigation";

const styles = stylex.create({
  base: {
    borderRightColor: semanticColors.neutralsLowest,
    borderRightStyle: "solid",
    borderRightWidth: stroke.regular,
  },
  header: {
    paddingBottom: spacing.s,
    paddingHorizontal: spacing.s,
  },
});

export type ParishAdminDashboardSettingsSidebarProps = Omit<
  SidebarProps,
  "children"
>;

export const ParishAdminDashboardSettingsSidebar = ({
  state,
  ...props
}: ParishAdminDashboardSettingsSidebarProps) => {
  const params = useParams<{ communityId: string }>();

  const parishAdminSettingsBaseUrl = `/admin/${params.communityId}/settings`;

  const parishAdminSettingsTabs: (Pick<SidebarTabProps, "icon" | "message"> & {
    path: string;
  })[] = [
    {
      icon: <InfoIcon />,
      path: "group-info",
      message: "community_settings_group_info",
    },
    {
      icon: <PermissionIcon />,
      path: "permissions",
      message: "community_settings_permissions",
    },
  ];

  return (
    <Sidebar state={state} styleXArray={[styles.base]} {...props}>
      <Text type="headline" size="m" styleXArray={[styles.header]}>
        Settings
      </Text>
      {parishAdminSettingsTabs.map(({ path, ...props }) => (
        <SidebarTab
          analyticsEvent={{
            event: HallowAnalyticsEvent.TappedCommunitySettings,
            properties: {
              options: path.replace("-", "_"),
              platform: "web",
            },
          }}
          href={`${parishAdminSettingsBaseUrl}/${path}`}
          key={path.toString()}
          state={state}
          {...props}
        />
      ))}
    </Sidebar>
  );
};
