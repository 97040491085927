"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { FlaggableType } from "@packages/sdk";
import {
  useCommunitiesAdminPost,
  useRequestCommunitiesAdminFlaggable,
} from "@packages/sdk";
import {
  ArrowRightIcon,
  Button,
  CheckmarkIcon,
  QuickAction,
  ScrollArea,
  Text,
  TrashIcon,
  useAlert,
  useConfirm,
  useScrollArea,
} from "@packages/ui";
import {
  colorsRaw,
  dropShadow,
  radius,
  semanticColors,
  semanticColorsRaw,
  shades,
  spacing,
  stroke,
  zIndices,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { Fragment, useEffect, useRef, useState } from "react";

import { Loader } from "../../Loader";
import { ReportedCarouselEmpty } from "../ReportedCarouselEmpty";
import { ReportedObject } from "../ReportedObject";

const ANIMATION_DELAY = 1000;

const styles = stylex.create({
  actionsContainer: {
    display: "flex",
    gap: spacing.xl,
    height: 80,
    justifyContent: "center",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingTop: spacing.xl,
    position: "relative",
    width: spacing.full,
  },
  overlay: {
    backgroundColor: semanticColors.background,
    height: spacing.full,
    left: 0,
    opacity: 0.3,
    position: "absolute",
    top: 0,
    transition: `${ANIMATION_DELAY}ms ease-out`,
    width: spacing.full,
    zIndex: zIndices.padReportedOverlay,
  },
  reportedObjectContainer: {
    width: "600px",
  },
  toast: {
    backgroundColor: semanticColors.background,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.s,
    borderWidth: stroke.light,
    boxShadow: `0 ${dropShadow.s} ${dropShadow.ms} 0 ${shades.shade10}`,
    left: "50%",
    paddingBottom: spacing.xxs,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    paddingTop: spacing.xxs,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    transition: "250ms ease-out",
    zIndex: zIndices.padReportedToast,
  },
  viewport: {
    /* 100vh - (actionsContainer height) - (PanelHeader height) - (MenuBar height) - (layout paddingBottom) - paddingTop */
    /* 100vh - 80px - 64px - 64px - 48px - 32px */
    height: "calc(100vh - 288px)",
    minHeight: 300,
  },
});

type ReportedCarouselProps = {
  communityId: number;
};

type FlaggedObjectIdentifier = {
  id: number;
  type: FlaggableType;
};

export const ReportedCarousel = ({ communityId }: ReportedCarouselProps) => {
  const analytics = useAnalytics();
  const viewportRef = useRef(null);
  const { scroll: scrollViewportRef } = useScrollArea({ viewportRef });
  const [activeReportedObjectIdentifier, setActiveReportedObjectIdentifier] =
    useState<FlaggedObjectIdentifier>();
  const [overlay, setOverlay] = useState<null | string>(null);
  const alert = useAlert();
  const t = useTranslations();

  const { query: communitiesAdminFlaggableQuery } =
    useRequestCommunitiesAdminFlaggable({
      id: communityId,
      // Setting staleTime to infinity to avoid the query refetching in the background and bringing the previously skipped records forward if the order changes
      staleTime: Infinity,
    });
  const dialog = useConfirm({
    cancelButton: t("general_word_cancel"),
    description: () => t("community_delete_confirmation_message"),
    primaryButton: (onClick) => (
      <Button variant="color" color={colorsRaw.red60} onClick={onClick}>
        {t("community_remove_post_action")}
      </Button>
    ),
    title: () => t("community_moderation_confirm_delete"),
  });

  const { query: flaggedObjectQuery } = useRequestCommunitiesAdminFlaggable({
    id: communityId,
    staleTime: Infinity,
  });

  const activeFlaggedObjectIndex =
    communitiesAdminFlaggableQuery.data?.results.findIndex(
      (flaggedObject) =>
        flaggedObject.flaggable_id === activeReportedObjectIdentifier?.id &&
        flaggedObject.flaggable_type === activeReportedObjectIdentifier?.type,
    );

  const activeReportedObject =
    communitiesAdminFlaggableQuery.data?.results[activeFlaggedObjectIndex];

  const handleNext = () => {
    if (!communitiesAdminFlaggableQuery.data) {
      return;
    }

    if (
      activeFlaggedObjectIndex + 1 >=
      communitiesAdminFlaggableQuery.data.results.length
    ) {
      communitiesAdminFlaggableQuery.refetch().then(() => {
        setActiveReportedObjectIdentifier(undefined);
      });
      return;
    }

    const nextIndex = activeFlaggedObjectIndex + 1;

    setActiveReportedObjectIdentifier({
      id: communitiesAdminFlaggableQuery.data.results[nextIndex].flaggable_id,
      type: communitiesAdminFlaggableQuery.data.results[nextIndex]
        .flaggable_type,
    });
  };

  useEffect(() => {
    scrollViewportRef({ behavior: "smooth", top: 0 });
  }, [activeReportedObjectIdentifier]);

  const getFlaggedObjectMutationOptions = (
    successMessageKey: "kept" | "deleted",
  ) => {
    return {
      onSuccess: () => {
        handleNext();
        setOverlay(
          t(
            successMessageKey === "deleted"
              ? "pad_moderation_removed"
              : "pad_moderation_kept",
          ),
        );
        setTimeout(() => {
          setOverlay(null);
        }, ANIMATION_DELAY);
      },
      onError: (error: Error) => {
        alert.show({
          title: t("general_word_error"),
          description: error.message,
        });
      },
    };
  };

  const { mutationResolve: resolvePost, mutationDelete: deletePost } =
    useCommunitiesAdminPost({
      id: communityId,
      mutationDeleteProps: getFlaggedObjectMutationOptions("deleted"),
      mutationResolveProps: getFlaggedObjectMutationOptions("kept"),
    });

  if (
    communitiesAdminFlaggableQuery.isLoading ||
    communitiesAdminFlaggableQuery.isError
  ) {
    return <Loader />;
  }

  // TODO: Add error notification for when the query fails once https://github.com/hallow-inc/ark/pull/503 is merged in

  if (
    !activeReportedObjectIdentifier &&
    communitiesAdminFlaggableQuery.data?.results.length > 0
  ) {
    setActiveReportedObjectIdentifier({
      id: communitiesAdminFlaggableQuery.data.results[0].flaggable_id,
      type: communitiesAdminFlaggableQuery.data.results[0].flaggable_type,
    });
  }

  const handleDelete = async () => {
    analytics.track({
      event: HallowAnalyticsEvent.CommunityReportResponded,
      properties: {
        flagged_type: activeReportedObject.flaggable_type,
        reason: activeReportedObject.flagged_records
          ?.map((record) => record.reason)
          ?.join(", "),
        response: "delete",
        source: "web",
      },
    });

    const confirm = await dialog.show();
    if (!confirm) return;

    await deletePost.mutateAsync({
      postId: activeReportedObject.flaggable.post_id,
    });
  };

  const handleKeep = async () => {
    analytics.track({
      event: HallowAnalyticsEvent.CommunityReportResponded,
      properties: {
        flagged_type: activeReportedObject.flaggable_type,
        reason: activeReportedObject.flagged_records
          ?.map((record) => record.reason)
          ?.join(", "),
        response: "keep",
        source: "web",
      },
    });
    await resolvePost.mutateAsync({
      postId: activeReportedObject.flaggable.post_id,
    });
  };

  const handleSkip = () => {
    analytics.track({
      event: HallowAnalyticsEvent.CommunityReportResponded,
      properties: {
        flagged_type: activeReportedObject.flaggable_type,
        reason: activeReportedObject.flagged_records
          ?.map((record) => record.reason)
          ?.join(", "),
        response: "skip",
        source: "web",
      },
    });
    handleNext();
  };

  if (flaggedObjectQuery.isSuccess && !activeReportedObject)
    return <ReportedCarouselEmpty />;

  if (activeReportedObject)
    return (
      <div {...stylex.props(styles.container)}>
        {overlay && (
          <Fragment>
            <div {...stylex.props(styles.overlay)} />
            <div {...stylex.props(styles.toast)}>
              <Text size="s" type="title">
                {overlay}
              </Text>
            </div>
          </Fragment>
        )}
        <ScrollArea
          dependencies={[activeReportedObject]}
          maskBottom
          viewportProps={{ ref: viewportRef }}
          viewportStyleXArray={[styles.viewport]}
        >
          <ReportedObject
            flaggableObject={activeReportedObject}
            styleXArray={[styles.reportedObjectContainer]}
          />
        </ScrollArea>
        <div {...stylex.props(styles.actionsContainer)}>
          <QuickAction
            onClick={handleKeep}
            icon={<CheckmarkIcon.SimpleOn />}
            size="l"
            variant="color"
            color={semanticColorsRaw.successSuccess.default}
          >
            {t("parish_admin_dashboard_moderation_keep")}
          </QuickAction>
          <QuickAction
            onClick={handleDelete}
            icon={<TrashIcon />}
            size="l"
            variant="color"
            color={semanticColorsRaw.errorError.default}
          >
            {t("general_word_delete")}
          </QuickAction>
          <QuickAction
            onClick={handleSkip}
            icon={<ArrowRightIcon />}
            size="l"
            variant="neutral"
          >
            {t("general_word_skip")}
          </QuickAction>
        </div>
      </div>
    );

  return <Loader />;
};

ReportedCarousel.displayName = "ReportedCarousel";
