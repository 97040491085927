import {
  type BibleManifest,
  type BibleTranslations,
  type CloudfrontCookie,
  requestBible,
  requestBibleManifest,
} from "@packages/sdk";
import { revalidateTag } from "next/cache";

export type UseBibleProps = {
  translation: string;
};

type UseBibleReturn = [
  number | null,
  BibleManifest | null,
  BibleTranslations["translations"],
  CloudfrontCookie[],
];

export const getBible = async ({
  translation,
}: UseBibleProps): Promise<UseBibleReturn> => {
  const bibleTranslations = await requestBible();
  let { cookies, translations } = bibleTranslations;
  const tid = translations.find((t) => t.name === translation)?.id ?? null;
  if (!tid) return [tid, null, translations, cookies];
  let manifest: BibleManifest;
  try {
    manifest = await requestBibleManifest({
      translationId: tid,
      cloudfrontCookies: cookies,
    });
  } catch (e) {
    if (e.status === 403) {
      revalidateTag("bible");
      const refreshedBible = await requestBible();
      manifest = await requestBibleManifest({
        cloudfrontCookies: refreshedBible.cookies,
        translationId: tid,
      });
      cookies = refreshedBible.cookies;
    } else {
      throw e;
    }
  }
  return [tid, manifest, translations, cookies];
};
