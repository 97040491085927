import type { FlaggedIntention, FlaggedPromptResponse } from "@packages/sdk";
import { FlaggableType } from "@packages/sdk";
import type { WithStylexArray } from "@packages/ui";
import type { HTMLAttributes } from "react";

import {
  ReportedCardIntention,
  ReportedCardPromptResponse,
} from "./reportedCards";

export type ReportedCardProps = WithStylexArray<
  Omit<HTMLAttributes<HTMLDivElement>, "children">
> & {
  data: FlaggedIntention | FlaggedPromptResponse;
  type: FlaggableType;
};

export const ReportedCard = ({ data, type, ...props }: ReportedCardProps) => {
  switch (type) {
    case FlaggableType.Intention:
      return <ReportedCardIntention data={data} {...props} />;
    case FlaggableType.PromptResponse:
      return <ReportedCardPromptResponse data={data} {...props} />;
    default:
      return null;
  }
};

ReportedCard.displayName = "ReportedCard";
