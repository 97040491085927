"use client";

import type { AnalyticsEvent } from "@packages/analytics";
import { useAnalytics } from "@packages/analytics";
import { usePathMatch } from "@packages/sdk";
import type { TabBarIcon, WithStylexArray } from "@packages/ui";
import { Button, useHover } from "@packages/ui";
import {
  numericPixels,
  radius,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import type { LinkProps } from "next/link";
import Link from "next/link";
import type { PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

const styles = stylex.create({
  button: {
    borderRadius: radius.s,
    height: spacing.xxl,
    justifyContent: "center",
    padding: numericPixels[0],
    width: numericPixels[104],
  },
});

export type MenuBarPageProps = WithStylexArray<PropsWithChildren<LinkProps>> & {
  icon?: TabBarIcon;
  override?: ReactNode;
  analyticsEvent?: AnalyticsEvent;
};

export const MenuBarPage = forwardRef<any, MenuBarPageProps>(
  (
    {
      children,
      href,
      icon,
      override,
      analyticsEvent,
      styleXArray = [],
      ...props
    },
    ref,
  ) => {
    const { hoverProps, isHovered } = useHover({});
    const analytics = useAnalytics();
    const matcher = usePathMatch();
    const Icon =
      icon &&
      (matcher(href.toString(), href.toString() === "/")
        ? icon.Filled
        : icon.Outline);

    return (
      <Button
        asChild
        ref={ref}
        styleXArray={[styles.button]}
        textProps={{ size: "s", type: "title" }}
        variant="transparentPrimary"
        {...hoverProps}
      >
        <Link
          href={href}
          onClick={() => analyticsEvent && analytics.track(analyticsEvent)}
          {...props}
        >
          {isHovered ? children : override ? override : <Icon />}
        </Link>
      </Button>
    );
  },
);

MenuBarPage.displayName = "MenuBarPage";
