"use client";

import type { SessionStatus } from "@packages/sdk";
import { useSession } from "@packages/sdk";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";

export type UseRedirectProps = {
  redirectPath?: string;
  sessionStatus?: SessionStatus;
};

export const useRedirect = ({
  redirectPath,
  sessionStatus,
}: UseRedirectProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const { status } = useSession();

  useEffect(() => {
    if (status === sessionStatus) {
      const searchParamsQueryString = new URLSearchParams(
        searchParams.toString(),
      ).toString();

      return router.push(
        `${redirectPath}${searchParamsQueryString.length > 0 ? `?${searchParamsQueryString}` : ""}`,
      );
    }
  }, [status]);
};
