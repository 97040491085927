"use client";

import { GoogleTagManager as GTM } from "@next/third-parties/google";
import { Consent, useAnalytics } from "@packages/analytics";

export const GoogleTagManager = () => {
  const { consent } = useAnalytics();

  if (
    Array.isArray(consent) &&
    consent.includes(Consent.ANALYTICS) &&
    consent.includes(Consent.ADVERTISING)
  ) {
    return <GTM gtmId={process.env.NEXT_PUBLIC_GTM_ID} />;
  }

  return null;
};
