"use client";

import { HallowAnalyticsEvent } from "@packages/analytics";
import { usePathMatch } from "@packages/sdk";
import type { SidebarProps, SidebarTabProps } from "@packages/ui";
import {
  CommunityAltIcon,
  CommunitySelector,
  ReportedCounter,
  ReportIcon,
  SettingsAltIcon,
  Sidebar,
  SidebarTab,
  UpwardTrendIcon,
} from "@packages/ui";
import { semanticColors, stroke } from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import { useParams } from "next/navigation";

const styles = stylex.create({
  base: {
    borderRightColor: semanticColors.neutralsLowest,
    borderRightStyle: "solid",
    borderRightWidth: stroke.regular,
  },
});

export type ParishAdminDashboardSidebarProps = Omit<
  SidebarProps,
  "children" | "state"
>;

export const ParishAdminDashboardSidebar = ({
  ...props
}: ParishAdminDashboardSidebarProps) => {
  const params = useParams<{ communityId: string }>();
  const matcher = usePathMatch();

  const parishAdminBaseUrl = `/admin/${params.communityId}`;

  const parishAdminTabs: (Pick<
    SidebarTabProps,
    "actionRight" | "icon" | "message"
  > & {
    sidebarState: SidebarProps["state"];
    path: string;
  })[] = [
    {
      icon: <UpwardTrendIcon />,
      message: "web_dashboard",
      path: "dashboard",
      sidebarState: "expanded",
    },
    {
      icon: <CommunityAltIcon />,
      message: "community_settings_members",
      path: "members",
      sidebarState: "closed",
    },
    {
      actionRight: (
        <ReportedCounter communityId={parseInt(params.communityId)} />
      ),
      icon: <ReportIcon />,
      message: "parish_admin_dashboard_navigation_reported",
      path: "reported",
      sidebarState: "expanded",
    },
    {
      icon: <SettingsAltIcon />,
      message: "settings_title",
      path: "settings",
      sidebarState: "closed",
    },
  ];

  const activeTab = parishAdminTabs.find((tab) =>
    matcher(`${parishAdminBaseUrl}/${tab.path}`),
  );
  const activeTabSidebarState = activeTab?.sidebarState ?? "expanded";

  return (
    <Sidebar
      state={activeTabSidebarState}
      styleXArray={[styles.base]}
      {...props}
    >
      <CommunitySelector triggerState={activeTabSidebarState} />
      {parishAdminTabs.map(({ sidebarState, path, ...props }) => (
        <SidebarTab
          key={path}
          href={`${parishAdminBaseUrl}/${path}`}
          state={activeTabSidebarState}
          analyticsEvent={{
            event: HallowAnalyticsEvent.TappedAdminDashboardTab,
            properties: {
              tab: path,
            },
          }}
          {...props}
        />
      ))}
    </Sidebar>
  );
};
