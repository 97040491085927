"use client";

import { useTranslations } from "@packages/i18n";
import {
  replaceS3ImageUrlWithImagesHallowApp,
  useRequestAvatars,
  useRequestMe,
} from "@packages/sdk";
import {
  ArrowLeftIcon,
  Button,
  CloseIcon,
  FullscreenModal,
  IconButton,
  Modal,
  ModalTab,
  ModalTabContainer,
  ModalTabContent,
  RadioImageInput,
  Text,
  TextInput,
  useDynamicViewport,
} from "@packages/ui";
import {
  numericPercentages,
  numericPixels,
  radius,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as RadixUIDialog from "@radix-ui/react-dialog";
import * as stylex from "@stylexjs/stylex";
import Image from "next/image";
import { useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 1024px)";

const styles = stylex.create({
  areaContent: {
    height: numericPercentages[100],
    padding: {
      [cssMediaMinWidthDesktop]: spacing.l,
      default: "unset",
    },
  },
  avatarContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  avatarGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  close: {
    marginBottom: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: spacing.xxs,
    },
  },
  content: {
    maxWidth: numericPercentages[100],
    width: numericPixels[480],
  },
  description: {
    marginBottom: spacing.none,
    marginTop: spacing.s,
  },
  editButton: {
    marginTop: spacing.ms,
  },
  fitModalTab: {
    borderRadius: radius.full,
    marginTop: {
      [cssMediaMinWidthDesktop]: spacing.ms,
      default: spacing.none,
    },
    minWidth: "unset",
    padding: spacing.none,
    width: "fit-content",
  },
  flexContainer: {
    display: "flex",
    flexDirection: {
      [cssMediaMinWidthDesktop]: "column",
      default: "column-reverse",
    },
  },
  formAvatar: {
    marginTop: spacing.l,
  },
  formName: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: spacing.ml,
    },
    width: numericPercentages[100],
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: numericPercentages[100],
  },
  headerTitle: {
    flex: 1,
    marginRight: numericPixels[40],
    textAlign: "center",
  },
  input: {
    paddingBottom: spacing.ms,
  },
  modalTabContainer: {
    height: {
      [cssMediaMinWidthDesktop]: numericPixels[640],
      default: "100vh",
    },
    maxHeight: {
      [cssMediaMinWidthDesktop]: "95vh",
      default: "100vh",
    },
  },
  modalTabContent: {
    height: numericPercentages[100],
  },
  modalTabContentAvatar: {
    paddingBottom: numericPixels[25],
  },
  radioImageLabel: {
    borderRadius: radius.full,
    marginBottom: spacing.l,
  },
  saveButton: {
    bottom: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: numericPixels[40],
    },
    position: "sticky",
  },
  tabContent: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
    height: numericPercentages[100],
  },
  titleContainer: {
    marginBottom: {
      [cssMediaMinWidthDesktop]: spacing.l,
      default: spacing.xl,
    },
    marginTop: {
      [cssMediaMinWidthDesktop]: spacing.xl,
      default: spacing.s,
    },
  },
  updateButton: {
    bottom: {
      [cssMediaMinWidthDesktop]: numericPixels[0],
      default: numericPixels[20],
    },
    marginTop: {
      [cssMediaMinWidthDesktop]: spacing.ms,
      default: spacing.none,
    },
    position: "relative",
  },
});

type UpdateProfileModalProps = {
  trigger: JSX.Element;
};

type NameInput = {
  name: string;
  last_name: string;
};

type AvatarInput = {
  id: number;
};

export const UpdateProfileModal = ({ trigger }: UpdateProfileModalProps) => {
  const t = useTranslations();

  const isDesktopViewport = useDynamicViewport({ minimumWidth: 1024 });
  const userAvatarDimensions = isDesktopViewport ? 148 : 116;
  const avatarDimensions = isDesktopViewport ? 116 : 90;

  const {
    query: user,
    mutationAvatar: updateAvatar,
    mutationPut: updateUser,
  } = useRequestMe();
  const { query: avatars } = useRequestAvatars();

  const [open, setOpen] = useState<boolean>(false);

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<NameInput>();

  const watchName = watch("name");
  const watchLastName = watch("last_name");

  const onNameSubmit: SubmitHandler<NameInput> = async (data) => {
    await updateUser.mutateAsync(data);
    reset();
    setOpen(false);
  };

  const {
    formState: { isValid: avatarIsValid },
    handleSubmit: avatarHandleSubmit,
    register: avatarRegister,
    reset: avatarReset,
    watch: avatarWatch,
  } = useForm<AvatarInput>();

  const watchAvatarId = avatarWatch("id");

  const onAvatarSubmit: SubmitHandler<AvatarInput> = async ({ id }) => {
    await updateAvatar.mutateAsync({ id });
    await user.refetch();
    avatarReset();
    setOpen(false);
  };

  const modalTabs = (
    <ModalTabContainer
      defaultValue={"update-profile"}
      styleXArray={[styles.modalTabContainer]}
    >
      <ModalTabContent
        key={"update-profile"}
        areaContentStyleXArray={[styles.areaContent]}
        styleXArray={[styles.modalTabContent]}
        value={"update-profile"}
        viewportProps={{ asChild: true }}
      >
        <div {...stylex.props(styles.tabContent)}>
          <RadixUIDialog.Close asChild>
            <IconButton
              icon={<CloseIcon />}
              variant="transparentPrimary"
              styleXArray={[styles.close]}
            />
          </RadixUIDialog.Close>
          <div {...stylex.props(styles.flexContainer)}>
            <div {...stylex.props(styles.avatarContainer)}>
              <Image
                alt={t("settings_profile_image")}
                height={userAvatarDimensions}
                src={replaceS3ImageUrlWithImagesHallowApp(user.data?.image_url)}
                width={userAvatarDimensions}
              />
              <ModalTab
                showChevron={false}
                styleXArray={[styles.fitModalTab, styles.editButton]}
                value={"edit-profile-image"}
              >
                <Button size="s" variant="neutral">
                  {t("profile_edit")}
                </Button>
              </ModalTab>
            </div>
            <div {...stylex.props(styles.titleContainer)}>
              {isDesktopViewport ? (
                <Text size="m" type="headline">
                  {t("update_profile")}
                </Text>
              ) : (
                <Text size="xl" type="title">
                  {t("update_profile")}
                </Text>
              )}
              <Text asChild size="m" type="body">
                <p {...stylex.props(styles.description)}>
                  {t("campaign_enter_your_name")}
                </p>
              </Text>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onNameSubmit)}
            {...stylex.props(styles.formName)}
          >
            <div>
              <TextInput.Field
                name={"name"}
                placeholder={t("settings_edit_user_input_hint_name_required")}
                control={control}
                required
                styleXArray={[styles.input]}
                value={watchName}
              />
              {errors.name && (
                <span>{t("input_validation_error_required")}</span>
              )}

              <TextInput.Field
                name={"last_name"}
                placeholder={t(
                  "settings_edit_user_input_hint_last_name_required",
                )}
                control={control}
                required={true}
                styleXArray={[styles.input]}
                value={watchLastName}
              />
              {errors.last_name && (
                <span>{t("input_validation_error_required")}</span>
              )}
            </div>

            <Button
              disabled={!isValid}
              isFullWidth={true}
              size="l"
              styleXArray={[styles.updateButton]}
              type="submit"
            >
              {t("profile_update")}
            </Button>
          </form>
        </div>
      </ModalTabContent>
      <ModalTabContent
        areaContentStyleXArray={[styles.areaContent]}
        key={"edit-profile-image"}
        maskBottom={isDesktopViewport ? false : true}
        styleXArray={[styles.modalTabContent, styles.modalTabContentAvatar]}
        value={"edit-profile-image"}
      >
        <div {...stylex.props(styles.headerContainer)}>
          <ModalTab
            showChevron={false}
            styleXArray={[styles.fitModalTab]}
            value={"update-profile"}
          >
            <IconButton
              icon={<ArrowLeftIcon />}
              variant={"transparentPrimary"}
            />
          </ModalTab>
          <Text size="m" type="title" styleXArray={[styles.headerTitle]}>
            {t("settings_edit_profile")}
          </Text>
        </div>
        <div>
          <form
            onSubmit={avatarHandleSubmit(onAvatarSubmit)}
            {...stylex.props(styles.formAvatar)}
          >
            <div {...stylex.props(styles.avatarGrid)}>
              {avatars.data?.map((avatar) => (
                <RadioImageInput
                  selected={watchAvatarId}
                  id={`avatar-${avatar.id}`}
                  label={"id"}
                  register={avatarRegister}
                  styleXArray={[styles.radioImageLabel]}
                  value={`${avatar.id}`}
                >
                  <Image
                    alt={`avatar ${avatar.id}`}
                    height={avatarDimensions}
                    src={replaceS3ImageUrlWithImagesHallowApp(avatar.url)}
                    width={avatarDimensions}
                  />
                </RadioImageInput>
              ))}
            </div>

            <Button
              disabled={
                !avatarIsValid &&
                watchAvatarId &&
                !!avatars.data.find((a) => a.id === watchAvatarId).url
              }
              isFullWidth={true}
              size="l"
              styleXArray={[styles.saveButton]}
              type="submit"
            >
              {t("save_profile_picture")}
            </Button>
          </form>
        </div>
      </ModalTabContent>
    </ModalTabContainer>
  );

  if (isDesktopViewport)
    return (
      <Modal
        open={open}
        onOpenChange={setOpen}
        contentStyleXArray={[styles.content]}
        trigger={trigger}
      >
        {modalTabs}
      </Modal>
    );

  return (
    <FullscreenModal
      open={open}
      onOpenChange={setOpen}
      showCloseButton={false}
      trigger={trigger}
    >
      {modalTabs}
    </FullscreenModal>
  );
};
