"use client";

import { I18nMessageClient } from "@packages/i18n";
import { useRequestCommunityMembers } from "@packages/sdk";
import type { SidebarProps } from "@packages/ui";
import { Sidebar, Text, useIntersectionObserverRefState } from "@packages/ui";
import {
  semanticColors,
  spacing,
  stroke,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useParams } from "next/navigation";
import { useEffect, useRef } from "react";

const styles = stylex.create({
  base: {
    borderRightColor: semanticColors.neutralsLowest,
    borderRightStyle: "solid",
    borderRightWidth: stroke.regular,
  },
  header: {
    paddingBottom: spacing.s,
    paddingHorizontal: spacing.s,
  },
  infiniteBuffer: {
    height: 1,
    width: spacing.full,
  },
});

export type ParishAdminDashboardMembersSidebarProps = Omit<
  SidebarProps,
  "children"
>;

export const ParishAdminDashboardMembersSidebar = ({
  state,
  ...props
}: ParishAdminDashboardMembersSidebarProps) => {
  const { communityId } = useParams<{ communityId: string }>();

  const infiniteScrollBufferRef = useRef<HTMLDivElement>(null);
  const [infiniteScrollBufferRefShowing] = useIntersectionObserverRefState({
    ref: infiniteScrollBufferRef,
  });

  const { infiniteQuery: communityMembersInfiniteQuery } =
    useRequestCommunityMembers({
      id: parseInt(communityId),
    });

  useEffect(() => {
    if (
      infiniteScrollBufferRefShowing &&
      communityMembersInfiniteQuery.hasNextPage &&
      !communityMembersInfiniteQuery.isFetchingNextPage
    )
      communityMembersInfiniteQuery.fetchNextPage();
  }, [infiniteScrollBufferRefShowing]);

  return (
    <Sidebar state={state} styleXArray={[styles.base]} {...props}>
      <Text type="headline" size="m" styleXArray={[styles.header]}>
        <I18nMessageClient message="community_settings_members" />
      </Text>
      {communityMembersInfiniteQuery.data?.pages?.map(
        (communityMembersInfiniteQueryPage) =>
          communityMembersInfiniteQueryPage?.results?.map((member) => (
            <Link
              href={`/admin/${communityId}/members/${member.id}`}
              key={member.id}
            >
              {member.id}
            </Link>
          )),
      )}
      <div
        ref={infiniteScrollBufferRef}
        {...stylex.props(styles.infiniteBuffer)}
      />
    </Sidebar>
  );
};
